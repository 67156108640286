//Need to add all school id, temp added few for testing.

const notfoundroute = {
  path: "*",
  name: "NotFound",
  component: () =>
    import(/* webpackChunkName: "NotFound" */ "./views/NotFound.vue"),
  meta: {
    sitemap: {
      slugs: ["louisiana schools"],
    },
  },
};
export const routes = [
  {
    path: "/",
    name: "layout",
    component: () =>
      import(/* webpackChunkName: "masterLayout" */ "./views/MasterLayout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "./views/Home.vue"),
        meta: {
          sitemap: {
            slugs: ["louisiana schools"],
          },
        },
      },
    /*   {
        path: "charts",
        name: "Charts",
        component: () =>
          import(* webpackChunkName: "Charts" * "./views/Charts.vue"),
        meta: {
          sitemap: {
            slugs: ["louisiana schools"],
          },
        },
      },
       {
        path: "sample",
        name: "Sample",
        component: () =>
          import(* webpackChunkName: "sample" * "./views/Sample.vue"),
        meta: {
          sitemap: {
            slugs: ["louisiana schools"],
          },
        },
      },  */
      {
        path: "compare",
        name: "Compare",
        component: () =>
          import(/* webpackChunkName: "compare" */ "./views/CompareList.vue"),
        meta: {
          sitemap: {
            slugs: ["louisiana schools"],
          },
        },
      },
      {
        path: "favorites",
        name: "fav",
        component: () =>
          import(/* webpackChunkName: "fav" */ "./views/FavoriteList.vue"),
      },
      {
        path: "comingsoon",
        name: "ComingSoon",
        component: () =>
          import(/* webpackChunkName: "comingSoon" */ "./views/ComingSoon.vue"),
        meta: {
          sitemap: {
            slugs: ["louisiana schools"],
          },
        },
      },
      {
        path: "schools/:schoolId/:schoolname?",
        name: "SchoolDetail",
        component: () =>
          import(
            /* webpackChunkName: "schoolDetail" */ "./views/SchoolDetail.vue"
          ),
        meta: {
          sitemap: {
            //slugs: schoolsIdList,
            slugs: [{
              schoolId:        "louisiana schools",
              schoolname:     "louisiana schools",
            }],
          },
        },
        children: [
          {
            path: "about-our-school/:schoolname?",
            name: "About School",
            component: () =>
              import(
                /* webpackChunkName: "schoolAbout" */ "./components/Application/SchoolAbout.vue"
              ),
            meta: {
              sitemap: {
               // slugs: schoolsIdList,
               slugs: [{
                schoolname:     "louisiana schools",
              }],
              },
            },
          },
          {
            path: "academic-performance",
            name: "Academic Performance",
            component: () =>
              import(
                /* webpackChunkName: "academicPerformance" */ "./components/Application/SchoolAcademicPerformance/SchoolAcademicPerformance.vue"
              ),
            meta: {
              sitemap: {
                slugs: ["louisiana schools"],
              },
            },
            children: [
              {
                path: "overall-performance",
                name: "Overall Performance",
                component: () =>
                  import(
                    /* webpackChunkName: "schoolOverallPerformance" */ "./components/Application/SchoolAcademicPerformance/SchoolOverallPerformance.vue"
                  ),
                meta: {
                  sitemap: {
                    slugs: ["louisiana schools"],
                  },
                },
                alias: ['academic-performance']
              },
              {
                path: "breakdown_student_groups",
                name: "Breakdown by Student Group",
                component: () =>
                  import(
                    /* webpackChunkName: "breakDownbyStudentGroup" */ "./components/Application/SchoolAcademicPerformance/BreakDownbyStudentGroup.vue"
                  ),
                meta: {
                  sitemap: {
                    slugs: ["louisiana schools"],
                  },
                },
              },
              {
                path: "TeacherWorkforce",
                name: "Teacher Workforce",
                component: () =>
                  import(
                    /* webpackChunkName: "teacherWorkforce" */ "./components/Application/TeacherWorkforce.vue"
                  ),
                meta: {
                  sitemap: {
                    slugs: ["louisiana schools"],
                  },
                },
              },
              {	
                path: "student_performance",	
                name: "student_performance",	
                component: () =>	
                  import(/* webpackChunkName: "StudentPerformance" */ "./components/Application/SchoolAcademicPerformance/StudentPerformance.vue"),	
              },
              {	
                path: "student_progress",	
                name: "studentprogress",	
                component: () =>	
                  import(/* webpackChunkName: "studentprogress" */ "./components/Application/SchoolAcademicPerformance/StudentProgress.vue"),	
                  meta: {
                    sitemap: {
                      slugs: ["louisiana schools"],
                    },
                  },
				      },
              {
                path: "*",
                name: "Future Academic Performance sections",
                component: () =>
                  import(
                    /* webpackChunkName: "futureAcademicPerformanceSections" */ "./views/ComingSoon.vue"
                  ),
                meta: {
                  sitemap: {
                    slugs: ["louisiana schools"],
                  },
                },
              },
            ],

          },
          {
            path: "ec-school-about/:schoolname?",
            name: "EC About School",
            component: () => import(/* webpackChunkName: "ecAbout" */"./components/Application/SchoolAcademicPerformance/ECAbout.vue"),
            meta: {
              sitemap: {
                slugs: [{
                  schoolname:     "louisiana schools",
                }],              },
            },
          },
          {
            path: "ec-school-about",
            name: "EC About School",
            component: () => import(/* webpackChunkName: "ecAbout" */"./components/Application/SchoolAcademicPerformance/ECAbout.vue"),
          },
          {
            path: "ec-academic-performance",
            name: "EC Academic Performance",
            component: () =>
              import(
                /* webpackChunkName: "ECAcademicPerformance" */ "./components/Application/SchoolAcademicPerformance/ECAcademicPerformance.vue"
              ),
            meta: {
              sitemap: {
                slugs: ["louisiana schools"],
              },
            },
          },
          {
            path: "finance",
            name: "School Spending",
            component: () =>
              import(
                /* webpackChunkName: "schoolSpending" */ "./components/Application/SchoolSpending.vue"
              ),
            meta: {
              sitemap: {
                slugs: ["louisiana schools"],
              },
            },
          },          
          {
            path: "COVID19Impact",
            name: "COVID-19 Impact",
            component: () =>
              import(
                /* webpackChunkName: "COVID19Impact" */ "./components/Application/COVID19Impact.vue"
              ),
            meta: {
              sitemap: {
                slugs: ["louisiana schools"],
              },
            },
          },
          // AcademicCOVID19Impact - Renamed to District COVID-19 Impact
          {
            path: "DistrictCOVID19Impact",
            name: "District COVID-19 Impact",
            component: () =>
              import(
                /* webpackChunkName: "COVID19Impact" */ "./components/Application/DistrictCOVID19Impact.vue"
              ),
            meta: {
              sitemap: {
                slugs: ["louisiana schools"],
              },
            },
          },
          {
            path: "DistrictSpending",
            name: "District Spending",
            component: () =>
              import(
                /* webpackChunkName: "COVID19Impact" */ "./components/Application/SchoolDistrictSpending.vue"
              ),
            meta: {
              sitemap: {
                slugs: ["louisiana schools"],
              },
            },
          },
          {
            path: "DisciplineandAttendance",
            name: "Attendance/Discipline",
            component: () =>
              import(
                /* webpackChunkName: "DisciplineandAttendance" */ "./components/Application/DisciplineandAttendance.vue"
              ),
            meta: {
              sitemap: {
                slugs: ["louisiana schools"],
              },
            },
          },
          {
            path: "Inspections",
            name: "Inspections",
            component: () =>
              import(
                /* webpackChunkName: "Inspections" */ "./components/Application/Inspections.vue"
              ),
            meta: {
              sitemap: {
                slugs: ["louisiana schools"],
              },
            },
          },
          {
            path: "/",
            name: "About School",
            
            component: () =>
              import(
                /* webpackChunkName: "schoolAbout" */ "./components/Application/SchoolAbout.vue"
              ),
            meta: {
              sitemap: {
                slugs: ["school name"],
              },
            },
          },
        ],
      },
      {
        path: "searchresults",
        name: "SearchResults",
        component: () =>
          import(
            /* webpackChunkName: "searchResults" */ "./views/SearchResults.vue"
          ),
        meta: {
          sitemap: {
            slugs: ["louisiana schools"],
          },
        },
      },
      {
        path: "schools",
        name: "Schools",
        component: () =>
          import(
            /* webpackChunkName: "searchResults" */ "./views/SearchResults.vue"
          ),
        meta: {
          sitemap: {
            slugs: ["louisiana schools"],
          },
        },
      },
      {
        path: "compareschools",
        name: "CompareSchools",
        component: () =>
          import(
            /* webpackChunkName: "compareSchools" */ "./views/CompareSchools.vue"
          ),
        meta: {
          sitemap: {
            slugs: ["louisiana schools"],
          },
        },
      },
      notfoundroute,
    ],
  },
  notfoundroute,
];



