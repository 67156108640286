import { SchoolSearchCriteria, SchoolCompareRequest, K12School } from "@/models/interface";
import axios from "axios";
import { BaseService } from "./base.service";
import { SchoolYear } from "@/models/interface";

class ApplicationService extends BaseService {
    private static instance: ApplicationService;

    public static get Instance() {
        console.log("process.env.NODE_ENV")
        console.log(process.env.NODE_ENV);
        return this.instance || (this.instance = new this());
    }

    public async getSchoolDetail(uniqueId: string): Promise<K12School> {
        try {
            const response = await axios.get(`${this.addApiToUrl}/SchoolDetail/GetSchoolDetailByUniqueId/${uniqueId}`);
            return response?.data?.result;
        } catch (error) {
            throw new Error(error);
        }
    }
    
    public async getAppSettings(key: string) {
        try {
            const response = await axios.get(`${this.addApiToUrl}/FacilityInspection/GetAppSettings/${key}`);

            return response?.data?.result;
        } catch (error) {
            throw new Error(error);
        }
    }
   
    public async getSchoolSearchResults(searchParams: SchoolSearchCriteria): Promise<any> {
        try {
            const response = await axios.get(`${this.addApiToUrl}/School/schoolbysearchparams`, { params: searchParams });

            return response?.data?.result;
        } catch (error) {
            throw new Error(error);
        }
    }
    public async makeGetRequest(url: string): Promise<any> {
        try {
            const response = await axios.get(url);

            return response?.data?.result;
        } catch (error) {
            throw new Error(error);
        }
    }

    public async getSchoolComparisonResults(compareParams: SchoolCompareRequest): Promise<any> {
        try {
            const response = await axios.get(`${this.addApiToUrl}/School/schoolComparisonDataPivot`, { params: compareParams });

            return response?.data?.result;
        } catch (error) {
            throw new Error(error);
        }
    }

    public async getLatLng(schoolId: any): Promise<any> {
        try {
            console.log("INSIDE APP Service");
            console.log(schoolId);
            //const response = await axios.get(`${this.addApiToUrl}/School/GetLatLng`, { params: schoolId });
            const response = await axios.get(`${this.addApiToUrl}/School/GetLatLng?q=` + schoolId);

            //"School/GetSchoolsByName?q=" + this.schoolSearchModel.schoolName;
            return response?.data?.result;
        } catch (error) {
            throw new Error(error);
        }


    }

    public async getGoogleAddress(searchParams: any): Promise<any> {
        try {
            console.log("INSIDE APP Service searchParams");
            console.log(searchParams);
            //const response = await axios.get(`${this.addApiToUrl}/School/GetLatLng`, { params: schoolId });
            const response = await axios.get(`${this.addApiToUrl}/School/GetLatLng?q=` + searchParams);

            //"School/GetSchoolsByName?q=" + this.schoolSearchModel.schoolName;
            return response?.data?.result;
        } catch (error) {
            throw new Error(error);
        }
    }

    public async getSchoolYear(): Promise<any> {
        try {
            const response = await axios.get(`${this.addApiToUrl}/School/GetSchoolYear`);
            return response?.data?.result;
        } catch (error) {
            throw new Error(error);
        }
    }

    public async getInspectionVisits(licenseNumber: string): Promise<any> {
        try {
            const response = await axios.get(`${this.addApiToUrl}/FacilityInspection/getInspectionVisits/${licenseNumber}`);
           
            return response?.data?.result;
        } catch (error) {
            throw new Error();
        }
    }

}

// export a singleton instance in the global namespace
export const applicationService = ApplicationService.Instance;