import { SchoolSearchCriteria, SchoolCompareRequest, K12School } from "@/models/interface";
import { applicationService } from "@/services/application.service";
import config from "../../config.json";

const setItemWithExpiry = (key: string, value: any) => {
    const now = new Date();

    const item = {
        value: value,
        expiry: now.getTime() + config.timeout // timeout: 300000 (5 minutes)
    }
    localStorage.setItem(key, JSON.stringify(item));
}

const getItemWithExpiry = (key: string) => {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}

const isSearchSameAsCache = (cache: SchoolSearchCriteria, search: SchoolSearchCriteria) => {
    const searchText = cache.searchText === search.searchText;
    const latitude = cache.latitude === search.latitude;
    const longitude = cache.longitude === search.longitude;
    const distance = cache.distance === search.distance;
    const gradelevel = cache.gradelevel === search.gradelevel;

    return searchText && latitude && longitude && distance && gradelevel;
}

const state = {
    status: "",
    message: "",
    schoolSearchResults: [],
    school: {},
    schools: [],
    showLoader: false,
    schoolSearchCriteria: {},
    schoolComparisonResults: [],
    schoolYear: {},
    mobileView: false,
    tabletView: false
};

const getters = {
    getschoolSearchCriteria: (appState: any) => {
        return appState.schoolSearchCriteria;
    },
    getschool: (appState: any) => {
        return appState.school;
    }
};

const actions = {
    async getSchoolDetail({ commit }: any, uniqueId: string): Promise<void> {
        try {
            if (!uniqueId) return;
            let cache = getItemWithExpiry("school-detail");
            let equal = false;
            if (cache) {
                const cachedUniqueId = (cache as K12School) && cache.schoolAbout && cache.schoolAbout.uniqueId;
                equal = cachedUniqueId === uniqueId;
            }
            if (!cache || !equal) {
                const result = await applicationService.getSchoolDetail(uniqueId);
                setItemWithExpiry("school-detail", result);
                cache = result;
            }
            commit("setSchool", cache);
        } catch (error) {
            commit("appError", error);
            localStorage.removeItem("school-detail");
        }
    },
    async getSchoolSearchResults({ commit }: any, searchCritera: SchoolSearchCriteria): Promise<void> {
        try {
            const cache = getItemWithExpiry("school-search-results");
            let searchResult;
            let equal = false;
            if (cache) {
                equal = isSearchSameAsCache(cache.criteria, searchCritera);
                searchResult = cache.result;
            }
            if (!cache || !equal) {
                const response = {
                    result: await applicationService.getSchoolSearchResults(searchCritera),
                    criteria: searchCritera
                };

                setItemWithExpiry("school-search-results", response);
                searchResult = response.result;
            }

            commit("setSchoolSearchResults", searchResult);
        } catch (error) {
            commit("appError", error);
            localStorage.removeItem("school-search-results");
        }
    },

    async getSchoolComparisonResults({ commit }: any, compareParams: SchoolCompareRequest): Promise<void> {
        try {
            const result = await applicationService.getSchoolComparisonResults(compareParams);
            console.log(result);
            commit("setSchoolComparisonResults", result);
            //return result;
        } catch (error) {
            commit("appError", error);
        }
    },

    async getLatLng({ commit }: any, schoolId: any): Promise<void> {
        try {
            console.log("INSIDE APP TS");
            console.log(schoolId);
            const result = await applicationService.getLatLng(schoolId);
            return result;
        } catch (error) {
            commit("appError", error);
        }
    },
    async getIsCareFacilityApplicable({ commit }: any, Key: any): Promise<void> {
        try {
            const result = await applicationService.getAppSettings(Key);
            return result;
        } catch (error) {
            commit("appError", error);
        }
    },
  
    async getGoogleAddress({ commit }: any, searchParams: any): Promise<void> {
        try {
            console.log("INSIDE APP getGoogleAddress TS");
            console.log(searchParams);
            const result = await applicationService.getGoogleAddress(searchParams);
            //commit("setSchoolSearchResults", result);
            return result;
        } catch (error) {
            commit("appError", error);
        }
    },

    async getSchoolYear({ commit }: any): Promise<void> {
        try {
            let cache = getItemWithExpiry("school-year");
            if (!cache) {
                const result = await applicationService.getSchoolYear();
                setItemWithExpiry("school-year", result);
                cache = result;
            }
            commit("setSchoolYear", cache);
        } catch (error) {
            commit("appError", error);
            localStorage.removeItem("school-year");
        }
    },

    async getInspectionVisits({ commit }: any,licenseNumber: any): Promise<void> {
        try {            
            const result = await applicationService.getInspectionVisits(licenseNumber);
           return result;
        } catch (error) {
            commit("appError", error);
        }
    },

    setDeviceDisplayType({ commit }: any) {
        commit('setMobileViewStatus',window.innerWidth <= 576);
        commit('setTabletViewStatus',window.innerWidth > 576);
    }
};

const mutations = {
    setLoader: (appState: any, payload: any) => {
        appState.showLoader = payload;
    },
    setschoolSearchCriteria: (appState: any, SearchCriteria: any) => {
        appState.schoolSearchCriteria = SearchCriteria;
    },
    appError: (appState: any, err: any) => {
        appState.status = "error";
        appState.message = err;
    },
    setSchool: (appState: any, school: any) => {
        appState.school = school;
    },
    setSchoolSearchResults: (appState: any, schoolSearchResults: any) => {
        appState.schoolSearchResults = schoolSearchResults;
    },
    setSchoolComparisonResults: (appState: any, schoolComparisonResults: any) => {
        appState.schoolComparisonResults = schoolComparisonResults;
    },
    setSchoolYear: (appState: any, schoolYear: any) => {
        appState.schoolYear = schoolYear;
    },
    setMobileViewStatus: (appState: any, isMobileView: boolean) => {
        appState.mobileView = isMobileView;
    },
    setTabletViewStatus: (appState: any, isTabletView: boolean) => {
        appState.tabletView = isTabletView;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}